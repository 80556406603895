<template>
  <div class="contents">
    <h1>此页面仍然在开发/This page is still in dev</h1>
    </div>
  </template>
  
  <script>
  export default {
    name: 'NavBtn'
  }
  </script>
  
  <style scoped>
  @import "@/css/main.css";
  </style>
  